import { Controller } from "stimulus";

class ControllerBase extends Controller {
  sourceTarget: HTMLElement
}

export default class extends (Controller as typeof ControllerBase) {
  static targets = ['source']

  copy(e: Event): void {
    if (window.getSelection) {
      window.getSelection().selectAllChildren(this.sourceTarget);
      if (document.execCommand('copy')) {
        alert(this.data.get("success-value"));
      }
    } else {
      alert(this.data.get("error-value"));
    }
  }
}
