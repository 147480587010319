import { Controller } from "stimulus";

import {
  JSONP_PATH,
  ZipData,
  loadData,
  init,
  getState,
  getData,
  getPrefName,
  getPrefKana,
} from "./postalcode_api";

class ControllerBase extends Controller {
  readonly hasPostalcodeTarget!: boolean;
  readonly postalcodeTarget!: HTMLInputElement;
  readonly addressTargets!: HTMLInputElement[];
}

/*
 * AjaxZipP の置き換え。
 * target=postalcode.postalcode を郵便番号として、
 * target=postalcode.address の内容を自動入力する
 *
 * <div data-controller="postalcode">
 *   <input data-postalcode-target="postalcode" />
 *   <input data-postalcode-target="address" />
 *   <input data-postalcode-target="address" data-address-format="$2" />
 *   <select data-postalcode-target="address" data-address-format="$0">
 *     <option></option>
 *     <option value="32">島根県</option>
 *   </select>
 * </div>
 *
 * controller option
 *  no-insert-hyphen: 郵便番号にハイフンを自動的に付加しない
 *  format: address のフォーマット (default: '$1$2$3$4')
 *    $0: 県コード '32'
 *    $00: 2桁県コード 左側を 0 でパディングする
 *    $1: 県名 '島根県'
 *    $2: 市町村名 '松江市'
 *    $3: 地域等 '北陵町'
 *    $4: 大字等 ''
 *    $5: 県名フリガナ
 *    $6: 市町村名フリガナ
 *    %7: 地域等フリガナ
 *  hiragana: フリガナが平仮名のデータを読み込む
 *  next-focus: 自動入力後にフォーカスする要素のセレクタ
 *
 * address option:
 *  address-format: controller のオプションと同じ
 */
export default class extends (Controller as typeof ControllerBase) {
  static targets = ["postalcode", "address"];

  initialize(): void {
    init();
    this.decode = this.decode.bind(this);
  }

  connect(): void {
    if (this.hasPostalcodeTarget) {
      if (!this.postalcodeTarget.dataset.action) {
        this.postalcodeTarget.dataset.action = `input->${this.identifier}#decode`;
      }
    }
  }

  invalid(): void {
    this.postalcodeTarget.classList.add("is-invalid");
    this.postalcodeTarget.classList.remove("is-valid");
  }

  reset(): void {
    this.postalcodeTarget.classList.remove("is-invalid");
    this.postalcodeTarget.classList.remove("is-valid");
  }

  decode(): void {
    var value = this.postalcodeTarget.value;
    if (!value) {
      this.reset();
      return;
    }
    
    value = this.postalcodeTarget.value = value.replace(/[０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    }).replace(/[−ー]/g, "-");

    // 000-?0000 に前方一致しない場合は invalid
    if (!/^\d{0,3}-?\d{0,4}$/.test(value)) {
      this.invalid();
      return;
    }

    // value から 3, 4 桁を取り出す
    let m, zip3, zip4;
    if ((m = value.match(/^(\d{3})-?(\d{4}$)?/))) {
      zip3 = m[1];
      zip4 = m[2];
    }

    // 前半 3 桁が見つからないときは reset
    if (!zip3) {
      this.reset();
      return;
    }

    // 前半 3 桁が決まった時点でデータのロードを開始する
    const state = getState(zip3, this.hiragana);

    if (!state) {
      this.reset();
      loadData(
        zip3,
        { jsonpPath: this.jsonpPath, hiragana: this.hiragana },
        this.decode
      );
      return;
    }

    // 後半の 4 桁がないときは停止
    if (!zip4) {
      return;
    }

    // loading or loaded
    if (state === "loaded") {
      const code = zip3 + zip4;
      const data = getData(code);
      if (data) {
        // value にハイフンがなければハイフンを付ける
        if (!this.noInsertHyphen && value === code) {
          this.postalcodeTarget.value = `${zip3}-${zip4}`;
        }
        this.setData(data);
        this.reset();
      } else {
        // 該当するデータがなければ invalid
        this.invalid();
      }
    } else {
      // データのロード待ち
      setTimeout(this.decode, 100);
    }
  }

  setData(data: ZipData): void {
    let target: HTMLElement;
    const defaultFormat = this.format;
    const prefName = getPrefName(data[0]);
    const prefKana = getPrefKana(data[0], this.hiragana);

    Array.from(this.addressTargets, (addressTarget) => {
      const format = addressTarget.dataset.addressFormat || defaultFormat;
      const value = format
        .replace("$00", data[0] < 10 ? "0" + data[0] : "" + data[0])
        .replace("$0", "" + data[0])
        .replace("$1", prefName)
        .replace("$2", data[1] || "")
        .replace("$3", data[2] || "")
        .replace("$4", data[3] || "")
        .replace("$5", prefKana || "")
        .replace("$6", data[4] || "")
        .replace("$7", data[5] || "");
      if (addressTarget.value && addressTarget.value.indexOf(value) === 0) {
        // 前半が一致する場合は何もしない
        return;
      }
      addressTarget.value = value;
      target = addressTarget;
    });

    const focusTarget = this.nextFocus || target
    focusTarget?.focus()
  }

  get nextFocus(): HTMLElement {
    const next = this.data.get("nextFocus");
    if (next) {
      return document.querySelector(next);
    }
  }

  get jsonpPath(): string {
    return this.data.get("jsonpPath") || JSONP_PATH;
  }

  get noInsertHyphen(): boolean {
    return this.data.has("noInsertHyphen");
  }

  get format(): string {
    return this.data.get("format") || "$1$2$3$4";
  }

  get hiragana(): boolean {
    return this.data.has("hiragana");
  }
}
