interface Window {
  AjaxZipP: { zipdata(data: ZipDataDictionary): void };
}
declare const window: Window;

interface AjaxZippEvent extends CustomEvent<ZipDataDictionary> {}

export type ZipData = [
  number,
  string | null,
  string | null,
  string | null,
  string | null,
  string | null
];
export type ZipDataDictionary = { [key: string]: ZipData };

// default data path
export const JSONP_PATH = "https://asp.pc-egg.com/ajaxzipp/jsonp";

const PREFMAP = {
  "1": ["北海道", "ほっかいどう", "ホッカイドウ"],
  "2": ["青森県", "あおもりけん", "アオモリケン"],
  "3": ["岩手県", "いわてけん", "イワテケン"],
  "4": ["宮城県", "みやぎけん", "ミヤギケン"],
  "5": ["秋田県", "あきたけん", "アキタケン"],
  "6": ["山形県", "やまがたけん", "ヤマガタケン"],
  "7": ["福島県", "ふくしまけん", "フクシマケン"],
  "8": ["茨城県", "いばらきけん", "イバラキケン"],
  "9": ["栃木県", "とちぎけん", "トチギケン"],
  "10": ["群馬県", "ぐんまけん", "グンマケン"],
  "11": ["埼玉県", "さいたまけん", "サイタマケン"],
  "12": ["千葉県", "ちばけん", "チバケン"],
  "13": ["東京都", "とうきょうと", "トウキョウト"],
  "14": ["神奈川県", "かながわけん", "カナガワケン"],
  "15": ["新潟県", "にいがたけん", "ニイガタケン"],
  "16": ["山梨県", "やまなしけん", "ヤマナシケン"],
  "17": ["長野県", "ながのけん", "ナガノケン"],
  "18": ["富山県", "とやまけん", "トヤマケン"],
  "19": ["石川県", "いしかわけん", "イシカワケン"],
  "20": ["福井県", "ふくいけん", "フクイケン"],
  "21": ["岐阜県", "ぎふけん", "ギフケン"],
  "22": ["静岡県", "しずおかけん", "シズオカケン"],
  "23": ["愛知県", "あいちけん", "アイチケン"],
  "24": ["三重県", "みえけん", "ミエケン"],
  "25": ["滋賀県", "しがけん", "シガケン"],
  "26": ["京都府", "きょうとと", "キョウトト"],
  "27": ["大阪府", "おおさかふ", "オオサカフ"],
  "28": ["兵庫県", "ひょうごけん", "ヒョウゴケン"],
  "29": ["奈良県", "ならけん", "ナラケン"],
  "30": ["和歌山県", "わかやまけん", "ワカヤマケン"],
  "31": ["鳥取県", "とっとりけん", "トットリケン"],
  "32": ["島根県", "しまねけん", "シマネケン"],
  "33": ["岡山県", "おかやまけん", "オカヤマケン"],
  "34": ["広島県", "ひろしまけん", "ヒロシマケン"],
  "35": ["山口県", "やまぐちけん", "ヤマグチケン"],
  "36": ["徳島県", "とくしまけん", "トクシマケン"],
  "37": ["香川県", "かがわけん", "カガワケン"],
  "38": ["愛媛県", "えひめけん", "エヒメケン"],
  "39": ["高知県", "こうちけん", "コウチケン"],
  "40": ["福岡県", "ふくおかけん", "フクオカケン"],
  "41": ["佐賀県", "さがけん", "サガケン"],
  "42": ["長崎県", "ながさきけん", "ナガサキケン"],
  "43": ["熊本県", "くまもとけん", "クマモトケン"],
  "44": ["大分県", "おおいたけん", "オオイタケン"],
  "45": ["宮崎県", "みやざきけん", "ミヤザキケン"],
  "46": ["鹿児島県", "かごしまけん", "カゴシマケン"],
  "47": ["沖縄県", "おきなわけん", "オキナワケン"],
};

const state: { [key: string]: "loading" | "loaded" } = {};
const cache: ZipDataDictionary = {};

export function loadData(
  zip3: string,
  options = {
    jsonpPath: JSONP_PATH,
    hiragana: false,
  },
  callback?: () => void
): void {
  const path = `${options.jsonpPath}${
    options.hiragana ? "-hiragana" : ""
  }/zip-${zip3}.js`;

  const key = getKey(zip3, options.hiragana);

  // load postal data
  state[key] = "loading";

  // load data with jsonp
  const s = document.createElement("script");
  s.setAttribute("src", `${path}?t=${new Date().getTime()}`);
  s.setAttribute("charset", "utf-8");
  s.onload = (): void => {
    state[key] = "loaded";
    callback?.call(null);
  };
  document.body.appendChild(s);
}

export function getPrefName(code: number): string {
  const pref = PREFMAP[`${code}`];
  return pref ? pref[0] : "";
}

export function getPrefKana(code: number, hiragana: boolean): string {
  const pref = PREFMAP[`${code}`];
  return pref ? pref[hiragana ? 1 : 2] : "";
}

export function getState(zip3: string, hiragana: boolean) {
  return state[getKey(zip3, hiragana)];
}

export function getData(zip7: string) {
  return cache[zip7];
}

export function init() {
  if (typeof window.AjaxZipP === "undefined") {
    const event = "ajaxzipp:onload";

    // zip-xxx.js がコールバックとして AjaxZipP.zipdata を呼ぶので定義する
    window.AjaxZipP = {
      zipdata: (data): void => fireEvent(event, data),
    };

    document.addEventListener(
      event,
      (evt) => {
        Object.assign(cache, (evt as AjaxZippEvent).detail);
      },
      false
    );
  }
}

function getKey(zip3: string, hiragana: boolean) {
  return hiragana ? `${zip3}-hiragana` : zip3;
}

function fireEvent(eventName: string, data: ZipDataDictionary): void {
  let event;
  try {
    event = new CustomEvent(eventName, {
      detail: data,
    });
  } catch (e) {
    event = document.createEvent("CustomEvent");
    event.initCustomEvent(eventName, false, false, data);
  }
  document.dispatchEvent(event);
}
